@charset "utf-8";
/* Bulma Responsive Tables */
@import "node_modules/bulma-responsive-tables/bulma-responsive-tables";

/* Bulma */
@import "node_modules/bulma/bulma";
// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");

// Set your brand colors
$purple: #8a4d76;
// $pink: #FA7C91;
$blue: #4f85a6;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $purple;
$link: $blue;

.modal {
  $modal-content-width: 2px;
}

.table th,
.table td {
  vertical-align: middle;
}
//ウィンドウサイズによる回り込み防止
$navbar-breakpoint: 0px;
$box-breakpoint: 0px;

main {
  padding: 0 0; /* 左右の余白を空ける */
}

.submenu {
  font-size: x-small;
  text-align: center;
  border-width: 0;
  background-color: white;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 5.2em;
}

:hover.submenu {
  background-color: lightblue;
}

.toolbar {
  border-width: 0;
  color: #485fc7;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 5.2em;
  font-size: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

:hover.toolbar {
  color: black;
}

.iconbutton {
  border-width: 0;
  background-color: rgba(0, 0, 0, 0);
  color: #485fc7;
}

:hover.iconbutton {
  color: black;
}

.tabbutton {
  border-width: 0;
  background-color: white;
  font-size: larger;
  padding: 12px;
}

.tabbuttonactive {
  border-width: 0;
  background-color: white;
  color: #485fc7;
  font-size: larger;
  border-bottom-color: #abbbfa;
  border-bottom-width: 2px;
  padding: 12px;
}

aside.box {
  padding: 1.25rem 1rem;
}

div.column {
  padding: 0.2rem;
}

.toolbarTD {
  width: 58px;
  vertical-align: middle;
}

.toolbarTD p {
  padding-left: 2px;
  padding-right: 2px;
}

.toolbarLink {
  width: 100%;
  color: #485fc7
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.clickableArea {
  display: block;
  width: 100%;
}

.clickableArea:hover {
  text-decoration-line: underline;
}

#navbar-size {
  height: 70px;
  justify-content: "center";
}
// Update some of Bulma's component variables

$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

#updatesuccsess {
  opacity: 1;
  transition: opacity 1s;
}

#updatesuccsess.fade {
  opacity: 0;
}

.product-version {
  font-size: 9pt;
  font-weight: bolder;
}
.data-version {
  font-size: 11pt;
}

#dropzone {
  width: 600px;
  border: 2px dotted #bbb;
  border-radius: 10px;
  padding: 35px;
  color: #bbb;
  text-align: center;
}

.tablestyle,
.tablestyle td,
.tablestyle th {
  border: 1px darkgray solid;
  text-align: center;
  padding: 2px 8px 2px 8px;
}

.medicineBackColor {
  border: 1px lightgray solid;
  display: block;
  height: 1.4em;
  width: 100%;
  margin-top: 0.2em;
}

// Import only what you need from Bulma
@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../node_modules/bulma/sass/base/_all.sass";
@import "../../node_modules/bulma/sass/elements/button.sass";
@import "../../node_modules/bulma/sass/elements/container.sass";
@import "../../node_modules/bulma/sass/elements/title.sass";
@import "../../node_modules/bulma/sass/form/_all.sass";
@import "../../node_modules/bulma/sass/components/navbar.sass";
@import "../../node_modules/bulma/sass/layout/hero.sass";
@import "../../node_modules/bulma/sass/layout/section.sass";
